<template>
  <div class="LodingProduct">
    <el-skeleton :loading="loading" animated>
      <template #template>
        <el-skeleton-item class="ProductImg" variant="image" />
        <div class="ProductName">
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
        </div>
        <el-skeleton-item class="ProductPrice" variant="text" />
      </template>
    </el-skeleton>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.LodingProduct {
  margin: 8px 0;
  .el-skeleton {
    width: 220px;
    height: 360px;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;

    .ProductImg {
      width: 220px;
      height: 220px;
    }
    .ProductName {
      width: 100%;
      height: 40px;
      margin: 0 8px;
    }
    .ProductPrice {
      margin: 0 8px;
      width: 50%;
    }
  }
}
</style>
